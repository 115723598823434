<template>
  <div class="padding has-background-white">
    <div class="changePassword-from-wrapper">
      <h3>Set new password</h3>

      <change-password :confirmToken="confirmToken"></change-password>

      <hr class="separator" />
      <router-link
        :to="{name: 'Login'}"
        class="has-text-weight-bold"
      >
        <span class="icon">
          <i class="fas fa-arrow-circle-left"></i>
        </span>
        <span>Back to log in</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import ChangePassword from '../components/Auth/ChangePassword.vue'

export default {
  name: 'PageChangePassword',
  components: { ChangePassword },

  data() {
    return {
      confirmToken: this.$route.params.confirmToken
    }
  },

  created() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

@media screen and (min-width: $desktop) {
  .changePassword-from-wrapper {
    margin: 0 auto;
    width: 70%;
  }
}
</style>
